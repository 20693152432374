import { ArrowRightIcon } from '@/assets/icons';
import {
  ListNewsCategoryHomePageProps,
  NewsProps,
} from '@/interfaces/NewsInterface';
import _navigate from '@/utils/navigateLink';
import Link from 'next/link';
import { LazyImage } from '../shared';
import { UrlLink } from '@/configs/urlLink';

const ListNewsCategory = ({
  name,
  slug,
  news,
}: ListNewsCategoryHomePageProps) => {
  return (
    <div>
      <div className="hidden flex-col lg:flex">
        <Link
          href={`${UrlLink.news.news}/${slug}`}
          className="border-neutral-grayLight hover:text-primary-eastern flex w-full items-center gap-5 border-t-[0.5px] py-3"
        >
          <h2 className="font-header text-2xl font-semibold">{name}</h2>
          <ArrowRightIcon />
        </Link>
        <div className="flex py-5">
          <div className="w-2/5">
            <Link
              href={
                news?.[0]?.slug
                  ? _navigate.detailNewsBySlugId(
                      news?.[0]?.slug,
                      news?.[0]?.id,
                      'news',
                    )
                  : _navigate.detailNewsByTitleId(
                      news?.[0]?.title,
                      news?.[0]?.id,
                      'news',
                    )
              }
              className="hover:text-primary-eastern flex flex-col gap-1"
            >
              <div className={`relative h-[304px] w-full`}>
                <LazyImage
                  className="object-fill"
                  src={news?.[0]?.thumbnail}
                  alt={news?.[0]?.title}
                  fill
                  sizes="(max-width: 768px) 30vw, 33vw"
                />
              </div>
              <h3 className="font-header p-1 py-2 text-2xl font-semibold">
                {news?.[0]?.title}
              </h3>
            </Link>
          </div>
          <div className="border-neutral-grayLight ml-6 h-fit w-3/5 border-l-[0.5px] pl-6">
            {news?.slice(1, 4)?.map((item: NewsProps, index: number) => {
              return (
                <Link
                  key={index}
                  href={
                    item?.slug
                      ? _navigate.detailNewsBySlugId(
                          item?.slug,
                          item?.id,
                          'news',
                        )
                      : _navigate.detailNewsByTitleId(
                          item?.title ?? '',
                          item?.id,
                          'news',
                        )
                  }
                  className={`flex items-start gap-5 py-3 pl-3 ${
                    index !== 2
                      ? 'border-neutral-grayLight border-b-[0.5px]'
                      : ''
                  } hover:text-primary-eastern`}
                >
                  <h3 className="text-PC-Title-Large grow px-3 py-4 font-sans">
                    {item.title}
                  </h3>
                  <div className={`relative h-[121px] w-[212px] min-w-[212px]`}>
                    <LazyImage
                      className="object-fill"
                      src={item?.thumbnail}
                      alt={item?.title ?? 'news'}
                      fill
                      sizes="10vw"
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:hidden">
        <Link
          href={`${UrlLink.news.news}/${slug}`}
          className="border-neutral-grayLight hover:text-primary-eastern flex w-full items-center gap-5 border-y-[0.5px] py-3"
        >
          <h2 className="font-header text-2xl font-semibold">{name}</h2>
          <ArrowRightIcon />
        </Link>
        <div className="flex flex-col py-5">
          <div className="w-full pb-3">
            <Link
              href={
                news?.[0]?.slug
                  ? _navigate.detailNewsBySlugId(
                      news?.[0]?.slug,
                      news?.[0]?.id,
                      'news',
                    )
                  : _navigate.detailNewsByTitleId(
                      news?.[0]?.title,
                      news?.[0]?.id,
                      'news',
                    )
              }
              className="hover:text-primary-eastern flex flex-col gap-1"
            >
              <div className={`relative h-[182px] w-full`}>
                <LazyImage
                  className="object-fill"
                  src={news?.[0]?.thumbnail}
                  alt={news?.[0]?.title}
                  fill
                  sizes="(max-width: 768px) 30vw, 10vw"
                />
              </div>
              <h3 className="text-PC-Display-H5 p-3 font-sans">
                {news?.[0]?.title}
              </h3>
            </Link>
          </div>
          <div className="border-neutral-grayLight h-fit w-full">
            {news?.slice(1, 4)?.map((item: NewsProps, index: number) => {
              return (
                <Link
                  key={index}
                  href={
                    item?.slug
                      ? _navigate.detailNewsBySlugId(
                          item?.slug,
                          item?.id,
                          'news',
                        )
                      : _navigate.detailNewsByTitleId(
                          item?.title ?? '',
                          item?.id,
                          'news',
                        )
                  }
                  className={`flex items-center justify-between gap-5 py-3 pl-3 ${
                    index !== 2
                      ? 'border-neutral-grayLight border-b-[0.5px]'
                      : ''
                  } hover:text-primary-eastern`}
                >
                  <h3 className="text-PC-Title-Medium line-clamp-3 font-sans">
                    {item.title}
                  </h3>
                  <div className={`relative h-[75px] w-[130px] min-w-[130px]`}>
                    <LazyImage
                      className="object-fill"
                      src={item?.thumbnail}
                      alt={item?.title ?? 'news'}
                      fill
                      sizes="(max-width: 768px) 20vw, 10vw"
                    />
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListNewsCategory;
